import Vue from 'vue'
import { API } from '@/plugins/api'
import { utils, writeFileXLSX } from 'xlsx'

Vue.prototype.$makeToast = function (mensajeColor, mensajeCabecera, mensajeCuerpo) {
  this.$bvToast.toast(mensajeCuerpo, {
    title: mensajeCabecera,
    variant: mensajeColor,
    solid: true,
    toaster: 'b-toaster-top-right',
    appendToast: true,
  })
}
Vue.prototype.$sendToast = function (tipo, texto, posicion = null) {
  if (posicion == null) {
    posicion = 'top-right'
  }
  this.$toast(texto, {
    type: tipo,
    timeout: 5000,
    position: posicion,
    closeOnClick: true,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    closeButton: 'button',
    icon: true,
    rtl: false,
  })
}
Vue.prototype.$validateState = function (ref) {
  if (this.veeFields[ref] && (this.veeFields[ref].dirty || this.veeFields[ref].validated)) {
    return !this.errors.has(ref)
  }
  return null
}
Vue.prototype.$fetchVuetable = async function (apiUrl, httpOptions) {
  const datos = await API.get(apiUrl, httpOptions)
  return datos.data
}

Vue.prototype.$makeExcel = async function (endpoint, filename, form) {
  try {
    const datos = await API.post(endpoint, form)
    const data = utils.json_to_sheet(datos.data.data)
    const workbook = utils.book_new()

    utils.book_append_sheet(workbook, data, filename)
    writeFileXLSX(workbook, `${filename}.xlsx`)
  } catch (error) {
    this.$sendToast('error', 'No se ejecuto la accion - ' + error.response.data.message)
  }
}
Vue.prototype.$makeExcel2 = async function (endpoint, filename, form) {
  try {
    const datos = await API.post(endpoint, form)
    // const data = utils.json_to_sheet(datos.data.data.resultados)
    // const workbook = utils.book_new()
    // utils.book_append_sheet(workbook, data, filename)
    // writeFileXLSX(workbook, `${filename}.xlsx`)

    const worksheet = utils.json_to_sheet(datos.data.data.resultados)
    const extras = [
      ['', ''],
      ['', ''],
      ['Proveedor:', datos.data.data.proveedor.razon_social],
      ['Busqueda desde:', `${datos.data.data.fecha_ini} al ${datos.data.data.fecha_fin}`],
      ['Total Debe:', `$${datos.data.data.total_debe}`],
      ['Total Haber:', `$${datos.data.data.total_haber}`],
      ['Saldo de la busqueda:', `$${datos.data.data.saldo_total}`],
      ['', ''],
      ['', ''],
      ['', ''],
      ['', ''],
    ]
    const newData = utils.sheet_add_aoa(worksheet, extras, { origin: -1 })
    const workbook = utils.book_new()
    utils.book_append_sheet(workbook, newData, datos.data.data.proveedor.razon_social)
    console.log(worksheet)
    writeFileXLSX(workbook, `${filename}.xlsx`)
  } catch (error) {
    this.$sendToast('error', 'No se ejecuto la accion - ' + error.response.data.message)
  }
}
